import React, { useState, useContext } from 'react';
import { Link, NavLink, useNavigate } from "react-router-dom";
import { LightningBoltIcon, UsersIcon, LogoutIcon, UserIcon, DownloadIcon } from '@heroicons/react/solid';
import GMI_LOGO from 'assets/gmi_logo.png';
import { AuthContext } from '../../shared/contexts/AuthContext';
import { SiteHeaderWrapper, DropdownNavMenu, DropdownNavMenuItem } from '../../shared/components/SiteHeaderWrappers';
import LogExportModal  from './LogExportModal';
import { PaperClipIcon } from '@heroicons/react/outline';

const getMenuItems = (client) => {
  const getUsersDashboard = client.get("/api/admin/users");
  
  const _menuItems = [
    {
      name: "Dashboard",
      description: "Projects Hub",
      href: "/dashboard",
      icon: <LightningBoltIcon className="w-4 h-4" />,
      permission: true,
    },
    {
      name: "My Account",
      description: "Update Account Info",
      href: "/dashboard/account",
      icon: <UserIcon className="w-4 h-4" />,
      permission: true,
    },
    {
      name: "Users Panel",
      description: "View Users",
      href: "/dashboard/users",
      icon: <UsersIcon className="w-4 h-4" />,
      permission: getUsersDashboard.canAccess(),
    },
  ];
  return _menuItems;
};

const exportButton = {
  name: "System Logs",
  description: "",
  icon: <DownloadIcon className="w-4 h-4" />,
};
const inactiveButton = {
  name: "Inactive Logs",
  description: "",
  icon: <DownloadIcon className="w-4 h-4" />,
};

const Miscellaneous = {
  name: "Miscellaneous",
  description: "",
  icon: <PaperClipIcon className="w-4 h-4" />,
};
const logoutMenuItem = {
  name: "Sign Out",
  description: "",
  icon: <LogoutIcon className="w-4 h-4" />,
};

const Header = () => {
  const { logoutUser, bouncerClient } = useContext(AuthContext);
  const [showSystemLogsModal, setShowSystemLogsModal] = useState(false);
  const [showInactiveLogsModal, setShowInactiveLogsModal] = useState(false);
  // const [showMiscellaneous, setShowMiscellaneous] = useState(false);

  const navigate = useNavigate(); 


  const convertSystemLogsToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    const fields = ["id", 'type', 'job_no', 'job_name', 'User.id', 'User.email', 'User.employeeId', "streamId"];
    const headers = ['Id', 'Type', 'Job No', 'Job Name', 'UserId', 'User Email', 'Employee Id', 'StreamId'];
    str += headers.join(',') + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let jobNoValue = array[i]['data'] && array[i]['data']['data'] 
                       ? array[i]['data']['data']['job_no'] || array[i]['data']['data']['job_number'] || '' 
                       : '';
  
      if (!jobNoValue) continue;
      let line = '';
      for (let j = 0; j < fields.length; j++) {
        if (line !== '') line += ',';
        let value;
        if (fields[j] === 'type') {
          value = array[i][fields[j]].replace(/([A-Z])/g, ' $1').trim();
        } else if (fields[j] === 'job_no') {
          value = array[i]['data'] && array[i]['data']['data']
            ? array[i]['data']['data'][fields[j]] || array[i]['data']['data']['job_number'] || ''
            : '';
        } else if (fields[j] === 'job_name') {
          value = array[i]['data'] && array[i]['data']['data']
            ? array[i]['data']['data'][fields[j]]
            : '';
        } else if (fields[j] === 'userEmail') {
          value = array[i]["User"]?.["email"] ?? "";
        } else if (fields[j] === 'useremployeeId') {
          value = array[i]["User"]?.["employeeId"] ?? "";
        } else {
          value = array[i][fields[j]] ?? "";
        }

        if (value == "null" || value == null) {
          value = '';
        }

        if (typeof value === 'object' && value !== null) {
          line += JSON.stringify(value);
        } else {
          line += value !== undefined ? value : '';
        }
      }
      str += line + '\r\n';
    }

    return str;
  };
  const convertInactiveLogsToCSV = (data) => {
    const headers = ['User Id', 'User Email', 'Employee Id'];
    const rows = data.map(item => [
      item.id,
      item.email,
      item.employeeId
    ]);

    let csvContent = headers.join(',') + '\r\n';
    rows.forEach(row => {
      csvContent += row.join(',') + '\r\n';
    });

    return csvContent;
  };

  const menuItems = getMenuItems(bouncerClient);

  return (

    <SiteHeaderWrapper>
      <span className="flex items-center text-xs">
        <Link to="/dashboard" title="go to projects dashboard">
          <BrandImage />
        </Link>
      </span>
      <DropdownNavMenu displayName="Menu">
        {menuItems.filter(({ permission }) => !!permission).map((item) => (
          <NavLink key={item.name} to={item.href}>
            <DropdownNavMenuItem item={item} />
          </NavLink>
        ))}
        <span onClick={() => setShowSystemLogsModal(true)} className="cursor-pointer">
          <DropdownNavMenuItem item={exportButton} />
        </span>
        <span onClick={() => setShowInactiveLogsModal(true)} className="cursor-pointer">
          <DropdownNavMenuItem item={inactiveButton} />
        </span>
        <NavLink to="/miscellaneous" >
          <DropdownNavMenuItem item={Miscellaneous} />
        </NavLink>
        <span onClick={logoutUser} className="cursor-pointer">
          <DropdownNavMenuItem item={logoutMenuItem} />
        </span>

      </DropdownNavMenu>

      <LogExportModal
        isOpen={showSystemLogsModal}
        onClose={() => setShowSystemLogsModal(false)}
        apiEndpoint="/api/exports/project_events"
        title="Choose Date to Export System Logs"
        fileNamePrefix="SystemLogs"
        convertToCSV={convertSystemLogsToCSV}
      />
      <LogExportModal
        isOpen={showInactiveLogsModal}
        onClose={() => setShowInactiveLogsModal(false)}
        apiEndpoint="/api/exports/inactive_logs"
        title="Choose Date to Export Inactive Logs"
        fileNamePrefix="InactiveLogs"
        convertToCSV={convertInactiveLogsToCSV}
      />

    </SiteHeaderWrapper>
  );
};

const BrandImage = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <div style={{ width: 44 }} className="mr-3 h-full">
      <img
        src={GMI_LOGO}
        alt="GMI Logo"
        onLoad={() => setIsLoaded(true)}
        className={`object-contain h-full ${isLoaded ? 'block' : 'hidden'}`}
      />
      {!isLoaded && (
        <div
          className={`h-5 w-full flex items-center justify-center opacity-75 bg-gray-200 animate-pulse rounded`}
        />
      )}
    </div>

  );
};

export default Header;
