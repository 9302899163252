import { useNavigate } from 'react-router-dom';
import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from 'shared/contexts/AuthContext';
import { DuplicateIcon, GlobeAltIcon } from '@heroicons/react/outline';
import { ViewListIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { styles } from "shared/components/Modal.tailwind";
import Button from "../../shared/components/Button";
import Header from "./Header";

const MiscellaneousDashboard = () => {
  const {user} = useContext(AuthContext);
  const navigate = useNavigate();
  const handleDuplicateProjects = () => {
    navigate("/duplicate-projects");
  };

  const handleManageRegions = () => {
     navigate("/manage-regions");
 };
  return (
    <>
      <Header />
      <div className={styles.subheaderTop}></div>
      <div className="py-0.5 mb-0.4 flex justify-end items-center h-12 text-sm bg-white text-gray-500 border-b-2">
        <Link to={"/dashboard"}>
          <button
            className="text-gray-600 font-normal hover:text-red-700 text-sm flex justify-end items-end w-auto h-auto border border-gray-600 hover:border-red-700 hover:bg-red-100 rounded-md py-1 px-2 mr-5">
            <ViewListIcon className="hover:text-red-700 w-5 h-5 mr-1.5" />
            All Projects
          </button>
        </Link>
      </div>
      <div className="p-8 max-w-2xl mx-auto mt-8">
        <h1 className="text-4xl font-bold mb-6 text-center">Miscellaneous</h1>
        <p className="text-lg text-gray-700 mb-8 text-center">
          Welcome to the Miscellaneous section! Here, you'll find additional tools and features.
        </p>


        <div className="flex justify-center space-x-4">
      {user?.role === "super admin" && (
          <Button
            className="flex items-center space-x-2  text-white text-3xl transition duration-200 ease-in-out rounded-lg shadow-md px-10 py-4 pr-4"
            iconElement={<GlobeAltIcon className="w-6 h-6 ml-1" />}
            innerText="Manage Regions"
            color="green"
            onClick={handleManageRegions}
          />
          )}
          <Button
            className="flex items-center space-x-2  text-white text-3xl rounded-lg px-10 py-4 pr-4"
            iconElement={<DuplicateIcon className="w-6 h-6 ml-1" />}
            innerText="Duplicate Projects"
            color="green"
            onClick={handleDuplicateProjects}
          />
      
        </div>
      </div>
    </>
  );
};

export default MiscellaneousDashboard;