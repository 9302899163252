import React, { useContext, Suspense } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from './shared/contexts/AuthContext';

const ProjectApp = React.lazy(() => import('./project/ProjectApp'));
const ProjectsDashboardApp = React.lazy(() => import('./dashboard/ProjectsDashboardApp'));

import Login from './Login';
import ResetPasswordForm from './ResetPasswordForm';
import InvitedUserRegisterForm from './InvitedUserRegisterForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import { FullPageLoadingSpinner } from './shared/components/LoadingSpinner';
import ErrorPage from './shared/components/ErrorPage';
import ErrorBoundaryWrapper from './ErrorBoundary1'; 
import MiscellaneousDashboard from 'dashboard/components/MiscellaneousDashboard';
import DuplicateProjects from 'dashboard/components/DuplicateProjects';
import ManageRegions  from 'dashboard/components/ManageRegion';
import './App.css';

//   static getDerivedStateFromError() {
//     return { hasError: true };
//   }

//   componentDidCatch(error, info) {
//     console.error('Error caught by ErrorBoundary1:', error, info);
//   }

//   render() {
//     if (this.state.hasError) {
//       return Navigate("/error");
//     }

//     return this.props.children; 
//   }
// }

const NoRoute = () => <Navigate to="/" />;

const ProtectedRoute = ({ element }) => {
  const { isLoggedIn, authLoading } = useContext(AuthContext);
  const location = useLocation();
  const referrer = location.pathname || "/";

  if (!isLoggedIn && !authLoading && !location.pathname.includes('work-complete')) {
    return (
      <Navigate 
        to={{
          pathname: "/",
          state: { referrer }
        }}
      />
    );
  }

  return element;
};

const AnonymousOnlyRoute = ({ element }) => {
  const { isLoggedIn, authLoading } = useContext(AuthContext);

  if (isLoggedIn && !authLoading) return <Navigate to="/" />;

  return element;
};

const App = () => {
  const { authLoading, isLoggedIn } = useContext(AuthContext);

  if (authLoading) return <FullPageLoadingSpinner />;

  return (
    <ErrorBoundaryWrapper>
      <Suspense fallback={<FullPageLoadingSpinner />}>
        <Routes>
          <Route path="/" element={isLoggedIn ? <Navigate to="/dashboard" /> : <Login />} />
          <Route path="/dashboard/*" element={<ProtectedRoute element={<ProjectsDashboardApp />} />} />
          <Route path="/projects/:project_access_token/*" element={<ProtectedRoute element={<ProjectApp />} />} />
          <Route path="/invite-user-link/:email_signup_token" element={<AnonymousOnlyRoute element={<InvitedUserRegisterForm />} />} />
          <Route path="/reset-password" element={<AnonymousOnlyRoute element={<ForgotPasswordForm />} />} />
          <Route path="/reset-password-link/:reset_password_token" element={<AnonymousOnlyRoute element={<ResetPasswordForm />} />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/miscellaneous" element={<ProtectedRoute element={<MiscellaneousDashboard />}/>}/>
          <Route path="/duplicate-projects"element={<ProtectedRoute element={<DuplicateProjects />}/>}/>  
          <Route path="/manage-regions" element={<ProtectedRoute element={<ManageRegions />}/>}/> 
          <Route path="*" element={<NoRoute />} />
        </Routes>
      </Suspense>
    </ErrorBoundaryWrapper>
  );
};

export default App;
