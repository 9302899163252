import React, { useState, useContext, useEffect } from "react";
import { getPluralModifiers } from 'gmi-utils';
import { FeedbackContext } from "../../shared/contexts/FeedbackContext";
import { PlusIcon, PencilIcon, TrashIcon, PaperClipIcon, ViewListIcon } from "@heroicons/react/outline";
import { get, post, put, $delete } from "../../util/axios";
import { formatDate } from "gmi-utils";
import { styles } from "shared/components/Modal.tailwind";
import { Cell, Row, Table } from "../../shared/components/Table/Table";
import DeleteRegionModal from "./DeleteRegionModal.js";
import Button from "../../shared/components/Button";
import { Link } from "react-router-dom";
import Header from "./Header";
import SearchBar from "../../shared/components/SearchBar";
import Pagination from "./Pagination";

const columnController = [
  { key: "name", displayName: "Region Name", width: "30%" },
  {
    key: "updatedAt",
    displayName: "Last Updated",
    width: "30%",
    formatter: (date) => <span>{formatDate(date)}</span>,
  },
];

const ManageRegion = () => {
  const [regions, setRegions] = useState([]);
  const [name, setName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editId, setEditId] = useState(null);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [filteredRegions, setFilteredRegions] = useState([]);
  const [searchRegion, setSearchRegion] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { showErrorToast, showSuccessToast } = useContext(FeedbackContext);
  const {  pluralSuffix } = getPluralModifiers(regions.length);

  useEffect(() => {
    fetchRegions();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    filterRegions();
  }, [searchRegion, regions]);

  useEffect(() => {
    filterRegions();
  }, [currentPage]);

  const onError = (error) => {
    console.error("An error occurred:", error);
  };

  const filterRegions = () => {
    const filtered = searchRegion
      ? regions.filter((region) =>
        region.name.toLowerCase().includes(searchRegion.toLowerCase())
      )
      : regions;

    const total = Math.ceil(filtered.length / itemsPerPage);
    setTotalPages(total);

    if (currentPage > total) setCurrentPage(total);

    const startIndex = (currentPage - 1) * itemsPerPage;
    setFilteredRegions(filtered.slice(startIndex, startIndex + itemsPerPage));
  };


  const handleChange = (e) => {
    setSearchRegion(e.target.value);
  };


  const fetchRegions = async () => {
    const onSuccess = (response) => {
      const sortedRegions = response.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setRegions(sortedRegions);
      setTotalPages(Math.ceil(sortedRegions.length / itemsPerPage));
      setCurrentPage(1);
    };
    const onError = (error) => console.log("An error occurred", error);
    try {
      await get("/api/manage-region", onSuccess, onError);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };


  const handleCreateOrEditRegion = async () => {
    const onSuccess = (res) => {
      const updatedRegions = editId
        ? regions.map((region) =>
          region.regionid === editId ? { ...region, name } : region
        )
        : [...regions, res];

      updatedRegions.sort((a, b) => a.name.localeCompare(b.name));

      setRegions(updatedRegions);
      setFilteredRegions(updatedRegions);
      setName("");
      setEditId(null);
      setIsModalOpen(false);

      if (editId) {
        showSuccessToast("Updated Successfully");
      } else {
        showSuccessToast("Created Successfully");
      }
    };

    const onError = (error) => {
      showErrorToast(error);
    };

    try {
      if (editId) {
        await put(`/api/manage-region/${editId}`, { name }, onSuccess, onError);

      } else {
        const res = await post( "/api/manage-region", { name }, onSuccess,onError );
      }
    } catch (error) {
      onError(error);
    }
  };


  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };



  const handleDeleteRegion = async () => {
    const onSuccess = () => {
      setRegions(
        regions.filter((region) => !selectedRegions.includes(region.regionid))
      );
      setSelectedRegions([]);
      setShowDeleteModal(false);
      showSuccessToast("Deleted Successfully");
    };

    const onError = (error) => console.log("An error occurred", error);

    try {
      await Promise.all(
        selectedRegions.map((id) =>
          $delete(`/api/manage-region/${id}`, onSuccess, onError)
        )
      );
      onSuccess();
    } catch (error) {
      onError(error);
    }
  };

  const handleEditClick = () => {
    const regionToEdit = regions.find(
      (region) => region.regionid === selectedRegions[0]
    );
    setName(regionToEdit.name);
    setEditId(regionToEdit.regionid);
    setIsModalOpen(true);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedRegions(filteredRegions.map((region) => region.regionid));
    } else {
      setSelectedRegions((prevSelected) =>
        prevSelected.filter(
          (regionid) => !filteredRegions.some((region) => region.regionid === regionid)
        )
      );
    }
  };
  const handleRowSelect = (regionid) => {
    setSelectedRegions((prevSelected) =>
      prevSelected.includes(regionid)
        ? prevSelected.filter((regionId) => regionId !== regionid)
        : [...prevSelected, regionid]
    );
  };


  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) setCurrentPage(page);
  };

  const goToNextPage = () => goToPage(currentPage + 1);
  const goToPreviousPage = () => goToPage(currentPage - 1);
  return (
    <>
      <Header />
      <div className={styles.subheaderTop}></div>
      <div className="py-0.5 mb-0.4  flex justify-between items-center h-14 text-sm bg-white text-gray-500 border-b-2">
        <div><h2 className="text-lg font-normal text-black ml-2">Manage Regions</h2></div>
        <div className="flex justify-end space-x-1">
          <Link to={"/miscellaneous"}>
            <button
              className="text-gray-600 font-normal hover:text-red-700 text-sm flex justify-end items-end w-auto h-auto border border-gray-600 hover:border-red-700 hover:bg-red-100 rounded-md py-1 px-2  ml-5">
              <PaperClipIcon className="hover:text-red-700 w-5 h-5 mr-1.5" />
              Miscellaneous
            </button>
          </Link>

          <Link to={"/dashboard"}>
            <button
              className="text-gray-600 font-normal hover:text-red-700 text-sm flex justify-end items-end w-auto h-auto border border-gray-600 hover:border-red-700 hover:bg-red-100 rounded-md py-1 px-2 mr-5">
              <ViewListIcon className="hover:text-red-700 w-5 h-5 mr-1.5" />
              All Projects
            </button>
          </Link>
        </div>
      </div>
      <div className="border-b h-16 bg-white flex justify-between items-center p-4">
      <div className="min-w-min flex items-center text-gray-600 text-sm ml-0.3">
            <div className="hidden md:block md:mr-1">You have</div>
            <div className="md:block md:mr-1">{regions.length}</div>
            <div className="md:block md:mr-1">{`Region${pluralSuffix}`}</div>
          </div>

        <div className="flex justify-end space-x-2 items-center p-2">
          <SearchBar
            value={searchRegion}
            onSearch={handleChange}
            placeholder="Search Region by Name..."
            className="w-[100px] mb-1 mt-4"
          />
          <Button
            innerText="Edit"
            iconElement={<PencilIcon className="w-4 h-4 ml-1" />}
            className="my-2 w-[120px]  flex justify-center items-center px-1"
            disabled={selectedRegions.length !== 1}
            onClick={handleEditClick}
            color="whiteDanger"
          />
          <Button
            innerText="Delete"
            iconElement={<TrashIcon className="w-4 h-4 ml-1" />}
            className="my-2 w-[120px]flex justify-center items-center"
            disabled={selectedRegions.length === 0}
            onClick={handleDeleteClick}
            color="whiteDanger"
          />
          <Button
            innerText="Add a new Region"
            iconElement={<PlusIcon className="w-4 h-4 mr-1" />}
            className="my-2 w-[280px]  flex justify-center items-center"
            color="green"
            onClick={() => setIsModalOpen(true)}
          />
        </div>
      </div>
      <main className="p-1 md:p-4 lg:p-1">
        <section className="overflow-x-auto bg-blueGray-100 shadow-sm text-gray-500 py-5 p-10 ">
          <Table
            columnHeaderDetails={columnController}
            hasHeaderCheckbox
            headerCheckboxIsChecked={
              selectedRegions.length > 0 &&
              filteredRegions.every((region) =>
                selectedRegions.includes(region.regionid)
              )
            }
            onCheckHeaderCheckbox={handleSelectAll}
            onError={onError}
          >
            <div
              className="overflow-y-auto hide-scrollbar"
              style={{ maxHeight: "calc(100vh - 260px)" }}
            >
              {Array.isArray(filteredRegions) &&
                filteredRegions.map((region) =>
                  region ? (
                    <Row
                      key={region?.id}
                      className={`bg-white ${region?.regionid === regions?.regionid ? "pl-16" : "p-0"
                        }`}
                      hasCheckbox={region?.id === regions?.id ? false : true}
                      isChecked={selectedRegions.includes(region?.regionid)}
                      onCheck={() => handleRowSelect(region.regionid)}
                    >
                      {columnController.map((col) => (
                        <Cell
                          key={col.key}
                          width={col.width}
                          className="text-sm text-gray-500"
                          hasCheckbox={true}
                        >
                          {col.formatter
                            ? col.formatter(region[col.key])
                            : region[col.key]}
                        </Cell>
                      ))}
                    </Row>
                  ) : null
                )}
            </div>
          </Table>
        </section>
        {totalPages > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            goToNextPage={goToNextPage}
            goToPreviousPage={goToPreviousPage}
            goToPage={goToPage}
          />
        )}
      </main>

      {showDeleteModal && (
        <DeleteRegionModal
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteRegion}
        />
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-md w-1/3">
          <h2>{editId ? "Edit a Region" : "Create a Region"}</h2>
            <input
              type="text"
              placeholder="Enter region name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 border rounded mb-4"
            />
            {errorMessage && (
              <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
            )}
            <div className="flex justify-end">
              <Button
                innerText="Cancel"
                className="mr-2"
                color="gray"
                onClick={() => {
                  setIsModalOpen(false);
                  setErrorMessage("");
                  setEditId(null);
                  setName('');
}}
              />
              <Button
                innerText={editId ? "Update" : "Create"}
                color="green"
                onClick={() => handleCreateOrEditRegion()}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageRegion;
